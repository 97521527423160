'use client'

import { QuickRepliesBadge32, StarListerBadge32 } from '@vinted/multichrome-icons'
import { Cell, Text, Icon } from '@vinted/web-ui'

import { useAbTest } from '@marketplace-web/shared/ab-tests'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { SellerBadgeTranslationKey, SellerBadgeType } from '../../constants/badges'

type Props = {
  type: SellerBadgeType
}

const SellerBadge = ({ type }: Props) => {
  const translate = useTranslate('user.seller_badges')

  const frequentUploadsAbTest = useAbTest('rename_star_wardrobe_badge')
  const showFrequentUploadsBadge = frequentUploadsAbTest && frequentUploadsAbTest.variant !== 'off'

  const SellerBadgeInfoByType = {
    [SellerBadgeType.ActiveLister]: {
      translationKey: showFrequentUploadsBadge
        ? SellerBadgeTranslationKey.FrequentUploads
        : SellerBadgeTranslationKey.ActiveLister,
      icon: StarListerBadge32,
    },
    [SellerBadgeType.FastReplier]: {
      translationKey: SellerBadgeTranslationKey.QuickReplier,
      icon: QuickRepliesBadge32,
    },
  }

  const { translationKey, icon } = SellerBadgeInfoByType[type]

  return (
    <div className="u-flexbox u-background-white">
      <Cell
        styling={Cell.Styling.Tight}
        prefix={<Icon name={icon} aria={{ 'aria-hidden': 'true' }} />}
        title={translate(`${translationKey}.title`)}
        body={
          <Text text={translate(`${translationKey}.body`)} type={Text.Type.Subtitle} as="span" />
        }
      />
    </div>
  )
}

export default SellerBadge
