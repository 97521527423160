'use client'

import { Component, JSXElementConstructor } from 'react'

import { EventTracker, TrackingContext } from '@marketplace-web/shared/event-tracker'
import { getDisplayName } from '@marketplace-web/shared/ui-helpers'

export type EventTrackerProps = {
  track: EventTracker['track']
}

export default function withTracking<P extends EventTrackerProps, C>(
  WrappedComponent: JSXElementConstructor<P> & C,
) {
  type Props = JSX.LibraryManagedAttributes<C, Omit<P, keyof EventTrackerProps>>

  return class InjectTracking extends Component<Props> {
    static displayName = getDisplayName(WrappedComponent)

    static WrappedComponent = WrappedComponent

    render() {
      return (
        <TrackingContext.Consumer>
          {trackerProps => {
            if (!trackerProps) throw new Error('Missing tracking provider')

            return <WrappedComponent {...(this.props as any)} track={trackerProps.tracker.track} />
          }}
        </TrackingContext.Consumer>
      )
    }
  }
}
