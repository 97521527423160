'use client'

import { useEffect } from 'react'
import { Button, Cell, Dialog, Navigation, Spacer, Text } from '@vinted/web-ui'
import { X24 } from '@vinted/monochrome-icons'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { ScrollableArea, SeparatedList } from '@marketplace-web/shared/ui-helpers'

import { viewScreenEvent } from '_libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { UserDto } from 'types/dtos'

import useFetchBusinessUserPolicies from './useFetchBusinessUserPolicies'

type Props = {
  user: UserDto
  show: boolean
  onClose: () => void
}

const PoliciesModal = ({ user, show, onClose }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('user.seller_policies')
  const { policies } = useFetchBusinessUserPolicies(user.business_account?.id)
  const isUKCompanyNumberHidden = useFeatureSwitch('hide_uk_sole_traders_company_number')
  const hideLegalCode =
    isUKCompanyNumberHidden &&
    user.business &&
    user.business_account?.entity_type === 'sole_trader' &&
    user.business_account?.country === 'UK'

  useEffect(() => {
    if (!show) return

    track(viewScreenEvent({ screen: Screen.SellerPolicies }))
  }, [track, show])

  function renderNavigation() {
    return (
      <Navigation
        body={translate('title')}
        right={<Button styling={Button.Styling.Flat} onClick={onClose} iconName={X24} />}
      />
    )
  }

  function renderLegalImprint() {
    return (
      <Cell styling={Cell.Styling.Wide}>
        <SeparatedList separator={<Spacer size={Spacer.Size.X2Large} />}>
          <Cell
            styling={Cell.Styling.Tight}
            title={<Text as="h1" type={Text.Type.Heading} text={translate('legal_imprint')} />}
            fullWidthTitle
          />
          <Cell
            styling={Cell.Styling.Tight}
            title={translate('business_name')}
            body={
              <Text
                as="span"
                text={user.business_account?.legal_name}
                testId="policies-modal-business-name"
              />
            }
          />
          {!hideLegalCode && (
            <Cell
              styling={Cell.Styling.Tight}
              title={translate('legal_code_number')}
              body={
                <Text
                  as="span"
                  text={user.business_account?.legal_code}
                  testId="policies-modal-legal-code"
                />
              }
            />
          )}
        </SeparatedList>
      </Cell>
    )
  }

  function renderTermsAndConditions() {
    return (
      <Cell
        styling={Cell.Styling.Wide}
        title={<Text as="h1" type={Text.Type.Heading} text={translate('terms_and_conditions')} />}
        body={
          <Text
            as="span"
            text={policies?.termsAndConditions || translate('not_specified')}
            testId="policies-modal-terms-conditions"
          />
        }
        fullWidthTitle
      />
    )
  }

  function renderReturnPolicy() {
    return (
      <Cell
        styling={Cell.Styling.Wide}
        title={<Text as="h1" type={Text.Type.Heading} text={translate('return_policy')} />}
        body={
          <Text
            as="span"
            text={policies?.returnPolicy || translate('not_specified')}
            testId="policies-modal-return-policy"
          />
        }
        fullWidthTitle
      />
    )
  }

  function renderAdditionalInformation() {
    return (
      <Cell
        styling={Cell.Styling.Wide}
        title={<Text as="h1" type={Text.Type.Heading} text={translate('additional_information')} />}
        body={
          <Text
            as="span"
            text={policies?.additionalInformation || translate('not_specified')}
            testId="policies-modal-additional-information"
          />
        }
        fullWidthTitle
      />
    )
  }

  return (
    <Dialog show={show} hasScrollableContent testId="policies-modal">
      <div className="u-fill-width">
        {renderNavigation()}
        <ScrollableArea>
          {renderLegalImprint()}
          {renderTermsAndConditions()}
          {renderReturnPolicy()}
          {renderAdditionalInformation()}
          <Spacer size={Spacer.Size.Large} />
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default PoliciesModal
