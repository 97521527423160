'use client'

import { Dialog, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

type Props = {
  isShown: boolean
  onClose: () => void
}

const GenericErrorModal = ({ isShown, onClose }: Props) => {
  const translate = useTranslate('checkout.generic_error_modal')

  return (
    <Dialog
      show={isShown}
      title={translate('title')}
      body={<Text as="span" text={translate('body')} alignment={Text.Alignment.Center} />}
      actions={[
        {
          text: translate('actions.close'),
          style: Dialog.ActionStyling.Filled,
          callback: onClose,
          testId: 'checkout-generic-error-modal-action-button',
        },
      ]}
      testId="checkout-generic-error-modal"
    />
  )
}

export default GenericErrorModal
